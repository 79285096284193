<template>
  <b-container>
    <b-card fluid>
      <!-- User Interface controls -->
      <b-row class="d-flex justify-content-between mb-1">
        <b-col lg="4" sm="6" md="5">
          <b-form-group
            label="Filtro"
            label-for="filter-input"
          >
            <b-input-group size="sm">
              <b-form-input
                v-if="!customPagination"
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar"
              ></b-form-input>

              <b-form-input
                v-else
                id="filter-input"
                v-model="customPaginationSearch"
                type="search"
                placeholder="Buscar"
                @blur="getCustomPaginationPage(1)"
              ></b-form-input>

              <!-- <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Limpar</b-button
                >
              </b-input-group-append> -->
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col v-show="!customPagination" lg="2" sm="4" md="3">
          <b-form-group
            label="Por página"
            label-for="per-page-select"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Main table element -->
      <b-table
        :items="customPagination ? customPaginationItems : items"
        :fields="fields"
        :current-page="customPagination ? 0 : currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        :busy="customPagination && customPaginationLoading"
        @filtered="onFiltered"
      >
        <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
        </template>

        <template #cell(actions)="row">
          <img
            v-if="type == 'training'"
            :id="`icon-pdf-img-${row.index}`"
            class="cursor-pointer"
            @click="$router.push(`/training/${row.item.id}`)"
            width="24px"
            src="@/assets/images/icons/file-icons/icon-pdf.png"
          />
          <img
            v-else
            :id="`icon-pdf-img-${row.index}`"
            class="cursor-pointer"
            @click="openDocument(row.item.path)"
            :width="showDelete ? '20px' : '24px'"
            src="@/assets/images/icons/file-icons/icon-pdf.png"
          />
          <feather-icon
            v-if="showDelete && company.role.name !== 'Client'"
            :id="`icon-pdf-delete-${row.index}`"
            class="cursor-pointer text-danger"
            icon="Trash2Icon"
            size="20"
            @click="deleteDocument(row.item.id)"
          />
          <feather-icon
            v-if="showGroup && company.role.name !== 'Client'"
            :id="`icon-pdf-group-${row.index}`"
            class="cursor-pointer text-primary"
            icon="InboxIcon"
            size="20"
            @click="groupDocument(row.item)"
          />
          <b-tooltip
            triggers="hover"
            placement="topright"
            :target="`icon-pdf-img-${row.index}`"
            :title="'Baixar PDF'"
            :delay="{ show: 750, hide: 50 }"
          />
        </template>

        <template #cell(index)="row">
          {{ customPagination ? (10 * (currentPage-1)) + row.index + 1 : row.index + 1 }}
        </template>

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>

      <b-row class="d-flex justify-content-center">
        <b-col sm="5" md="4" class="my-1 mt-5">
          <b-pagination
            v-model="currentPage"
            :total-rows="customPagination ? customPaginationTotalRows : totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
            @input="customPagination ? getCustomPaginationPage(currentPage) : ''"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import AWS from 'aws-sdk';
import {
  BFormCheckboxGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BInputGroupAppend,
  BPagination,
  BCard,
  BContainer,
  BRow,
  BCol,
  BModal,
  BCardText,
  BLink,
  BFormGroup,
  BTable,
  BInputGroup,
  BFormSelect,
  BIcon,
  BTooltip,
  BSpinner
} from 'bootstrap-vue';
export default {
  name: 'vx-table',
  components: {
    BCard,
    BContainer,
    BRow,
    BCol,
    BModal,
    BCardText,
    BLink,
    BFormGroup,
    BInputGroup,
    BFormSelect,
    BTable,
    BPagination,
    BButton,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInput,
    BIcon,
    BTooltip,
    BSpinner
  },
  props: {
    type: { type: String },
    items: { type: Array },
    fields: { type: Array },
    showDelete: { type: Boolean },
    showGroup: { type: Boolean },
    customPagination: { type: Boolean, default: false },
    customPaginationCall: { type: String },
    customPaginationCallObject: { type: Object, default: () => {} },
},
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      customPaginationItems: [],
      customPaginationTotalRows: 0,
      customPaginationSearch: '',
      customPaginationLoading: false,
      perPage: this.customPagination ? 10 : 5,
      pageOptions: [5, 10, 15, { value: 100, text: '100' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    company() {
      return this.$store.getters.company;
    },
  },
  watch: {
    items(val) {
      this.setPaginated(this.items.length);
    },
  },
  mounted() {
    // Set the initial number of items
    this.setPaginated(this.items.length);
    if(this.customPagination) this.getCustomPaginationPage(1)
  },
  methods: {
    setPaginated(val) {
      this.totalRows = val;
    },
    info(item, index, button) {
      this.savePdf(item);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.setPaginated(filteredItems.length);
      this.currentPage = 1;
    },

    savePdf(val) {
      if (this.type == 'training') {
        this.$store.dispatch('getTraining', val.id).then((resp) => {
          window.open(this.returnUrlPdf(resp.path), '_blank');
        });
      } else {
        this.$store.dispatch('getPdf', val.id).then((resp) => {
          window.open(this.returnUrlPdf(resp.path), '_blank');
        });
      }
    },

    openDocument(path) {
      window.open(this.returnUrlPdf(path), '_blank');
    },

    deleteDocument(item_id) {
      this.$emit('delete-document', item_id)
    },

    groupDocument(item){
      this.$emit('group-document', item)
    },

    returnUrlPdf(path) {
      const credentials = {
        accessKeyId: process.env.VUE_APP_S3_KEY,
        secretAccessKey: process.env.VUE_APP_S3_SECRET,
      };

      AWS.config.update({
        credentials: credentials,
        region: process.env.VUE_APP_S3_REGION,
      });

      const s3 = new AWS.S3();

      const presignedGETURL = s3.getSignedUrl('getObject', {
        Bucket: process.env.VUE_APP_S3_BUCKET,
        Key: path,
        Expires: 300,
      });

      return presignedGETURL;
    },
    getCustomPaginationPage(page){
      this.customPaginationLoading = true
      this.$store
        .dispatch(this.customPaginationCall, {
          ...this.customPaginationCallObject,
          search: this.customPaginationSearch,
          page: page
        })
        .then((resp) => {
          this.customPaginationItems = [];
          resp.data.map((item, index) => {
            let objectItems = {
              id: item.id,
              description: item.description ? item.description : '--',
              equipname: item.equipment
                ? item.equipment.description + ' - ' + item.equipment.model
                : '--',
              path: item.path,
              cnpj: item.client.num_doc,
              date: this.dateFormatter(item.created_at, 'DD/MM/yyyy'),
              client_name: item.client.name,
              client_id: item.client.id,
              // pagination_index: (10 * (page - 1)) + index,
            };
            this.customPaginationItems.push(objectItems);
          });
          this.customPaginationTotalRows = parseInt(resp.total)
          this.currentPage = page
          this.customPaginationLoading = false
        })
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.card-body{
  padding: 0px;
  margin: 0px;
  width: 100%;
}
</style>
